@import "../global/mixins";

.stickyCard {
    width: getRem(312);
    height: auto;
    background: var(--white);
    box-shadow: 0 getRem(5) getRem(10) rgba(0, 0, 0, 0.1);
    margin-bottom: auto;
    position: sticky;
    top: 20vh;
    bottom: getRem(230);
    right: 0;

    @media (max-width: 768px) {
        display: none;

        &.mobile {
            display: block;
        }
    }

    .stickyCardInner {
        width: 100%;
        // min-height: getRem(180);
        border-radius: getRem(8);
        display: flex;
        flex-direction: column;
        // overflow: hidden;
        padding: getRem(26) getRem(24) getRem(14);
        position: relative;

        &:before {
            content: '';
            width: 100%;
            height: getRem(10);
            background: url("/images/border-fry.svg") 50% no-repeat;
            background-size: cover;
            border-radius: getRem(8) getRem(8) 0 0;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
        }

        .filesContent {
            ul {
                list-style: none;
                padding: 0;
            }

            li {
                padding: getRem(16) 0;

                &:not(:last-child) {
                    border-bottom: 1px solid rgba(45, 45, 45, 0.2);
                }
            }

            svg {
                margin-top: getRem(4);
                margin-right: getRem(10);
                flex-shrink: 0;
            }

            .textContent {
                width: 100%;
            }

			.textContentBreak {
				word-break: break-all;
			}

            p {
                margin: 0;
                // word-break: break-all;
            }

            .openLabel {
                color: var(--text-light2);
                display: flex;
                align-items: center;
                font-weight: 700;
                margin-right: getRem(8);
            }

            .contentType {
                color: var(--text-light2);
                display: flex;
                justify-content: space-between;
                margin-top: getRem(10);
            }
        }

        .row {
            display: flex;
        }

        form {
            label {
                @include font(18, 24);
                font-weight: 700;
                letter-spacing: normal;
                text-transform: none;
            }

            textarea {
                width: 100%;
                min-height: getRem(80);
                color: var(--black);
                @include font(14, 18);

                &::placeholder {
                    color: var(--text-light);
                }
            }

            .btn {
                width: 100%;
                @include font(16, 28);
                padding: getRem(8) getRem(16);
            }
        }

        .successMsg {
            @include font(16, 20);
            margin: auto 0;
        }

        .redLabel {
            color: var(--red);
            @include font(14, 18);
            font-weight: 700;
            letter-spacing: 1.75px;
            margin: 0;
            text-transform: uppercase;
        }

        .anchorList {
            list-style: none;
            margin: getRem(10) 0 0;
            padding: 0;

            a {
                @include font(18, 32);
                color: var(--black);
                cursor: pointer;

                &.active {
                    color: var(--red);
                }
            }
        }

        .actionContent {
            ul {
                display: grid;
                grid-template-columns: repeat(4, 60px);
                grid-column-gap: getRem(8);
                list-style: none;
                padding: 0;

                li {
                    background: var(--light-grey);
                    border-radius: getRem(4);
                    display: flex;
                    align-items: center;
                    margin: 0;
                    padding: getRem(8);
                    position: relative;

                    button {
                        background: none;
                        border: none;
                        cursor: pointer;
                        display: flex;
                        align-items: center;

                        span {
                            @include font(14, 18);
                            font-weight: 700;
                            margin: 0 0 0 getRem(6);
                        }
                    }

                    ul li {
                        background: var(--white);
                        padding: getRem(16);
                    }
                }

                .thumbsUpItem {
                    button {
                        margin: auto;
                    }

                    :global {
                        .reaction-overlay {
                            width: 100% !important;
                            height: 100% !important;
                            @include font(2, 2);
                            opacity: 0;
                            position: absolute !important;
                            top: 0;
                            left: 0;
                            z-index: 101;
                        }
                    }
                }

                .bookmarkItem {
                    &>span {
                        margin: auto;
                    }

                    button {
                        span {
                            display: none;
                        }
                    }

                    .notBookmarked {
                        svg {
                            path {
                                stroke: var(--black);
                                stroke-width: getRem(2);
                            }
                        }
                    }
                }

                .followItem {
                    &>div {
                        margin: auto;
                        position: relative;
                    }

                    button {
                        position: relative;

                        span {
                            width: 100%;
                            height: 100%;
                            @include font(2, 2);
                            opacity: 0;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: -1;
                        }

                        svg {
                            path {
                                fill: var(--black);
                                stroke: var(--black);
                            }
                        }
                    }

                    .followOptionsContainer {
                        position: absolute;
                        top: 100%;
                        left: 0;
                    }
                }
            }

            .iconContainer {
                width: getRem(30);
                height: getRem(30);
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 0;

                &.active {
                    background: var(--reaction-thumbsUp);
                }
            }

            .reactUp {
                padding: 0;

                svg {
                    width: getRem(18);
                    height: getRem(18);
                }
            }

            .caseStudyLink {
                margin-top: getRem(16);

                svg {
                    margin-right: getRem(8);
                }

                p,
                a {
                    @include font(18, 24);
                    font-weight: 700;
                    margin: 0;
                }

                a {
                    color: var(--blue);

					&:hover {
						color: var(---blue-dark);
						text-decoration: underline;
					}
                }
            }
        }

        .nextCard {
            box-shadow: none;
            margin-top: getRem(16);

            h3 {
                margin-top: 0;
            }

            &>div {
                &>div {
                    &:first-child {
                        &>div {
                            padding: getRem(18) 0 0;
                        }
                    }

                    &:last-child {
                        padding: 0;

                        &>div {
                            &>div {
                                // flex-wrap: wrap;

                                p:first-child {
                                    // width: 100%;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.nextCard {
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
        }

        .nextItem {
            padding: getRem(12) 0;

            a {
                width: 100%;
                display: inline-flex;
            }

            .imageContainer {
                width: getRem(74);
                height: getRem(48);
                position: relative;
            }

            span {
                color: var(--black);
                font-weight: bold;
                margin: 0 0 0 getRem(12);
            }
        }
    }
}

.stickyContainer {
    display: flex;
    flex-direction: column;

    .stickyItem {
        flex: 1 1 auto;
        margin-bottom: getRem(16);
    }
}

.shareButtonContainer {
    position: relative;

    button {
        span {
            width: 100%;
            height: 100%;
            font-size: getRem(2) !important;
            opacity: 0;
            position: absolute !important;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }
}