@import "../global/mixins";
@import "../global/animations";

.stamp {
    width: getRem(30);
    min-width: getRem(30);
    height: getRem(30);
    border-radius: 100%;
    background-color: var(--red);
    color: var(--yellow);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.75rem;
}

.fileList {
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin: 0;
    padding: 0;
    @include breakpoint(tablet-desktop) {
        margin-left: 2rem;
        li:not(:last-child) {
            margin-right: 2rem;
        }
    }
    @include breakpoint(mobile) {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }
    svg {
        margin-right: 1em;
    }
    @include breakpoint(mobile) {
        li {
            padding-top: 1em;
        }
    }
    li {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 1em;
        color: var(--color-text);
        font-weight: bold;
        @include font(21, 21);
        &:not(:last-child) {
            padding-right: 1em;
        }
        // &:hover,
        // &:active {
        //     color: var(--red);
        // }
    }
}
